@charset "UTF-8";
/**
 * Theme Name:  STCH
 * Theme URI:   http://www.transportstch.com/
 * Description: Thème développé pour STCH
 * Author:      Webit Interactive
 * Author URI:  http://webitinteractive.ca
 * Version:     0.1.0
 * Tags:
 * Text Domain: stch
 *
 * License:     GPLv2+
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */
/* Normalize
 * ------------------------------------------- */
html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input[type=search] {
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

html {
  box-sizing: border-box;
}

*, *::after, *::before {
  box-sizing: inherit;
}

.u-color-primary, .landing__btn--primary:hover, .caracteristique .bg_image .block_contenu div p, .nos_services .menu_service a.active, .nos_services .menu_service a:hover, .contact_bg .entete .contact_txt a:hover, .blog_post .post_views:hover h3, .blog_post .post_views:hover blockquote, .blog_post .post_views:hover p, footer .droit .langue:hover, footer .menu_service ul li a:hover, footer .menu_footer ul li a:hover, .blog_nav .previous_post:hover a, .blog_nav .next_post:hover a, .blog_nav .previous_post:hover, .blog_nav .next_post:hover, .single-expert nav ul li.menu-item-object-expert a,
.single-service nav ul li.menu-item-object-service a,
.single-transport nav ul li.menu-item-object-transport a, nav.navbar .navbar-nav li.current-menu-item a, nav.navbar .navbar-nav li.tel, nav.navbar .navbar-nav li.soumission, nav.navbar .navbar-nav li a:hover {
  color: #2f75e7 !important;
}

.u-bg-primary, .resultat .block_blog:hover, .blog .block_blog:hover, .transport a:hover, .contact_bg .entete .block_tel, .blog_post .post_views .img_bg, nav .pagination li a:hover, .navbar-light .navbar-toggler:hover, nav.navbar .navbar-nav li.dropdown .dropdown-menu li.blue a, nav.navbar .navbar-nav li.dropdown:hover, nav.navbar .navbar-nav li.dropdown.show, .btn-header-landing:hover, .btn-form, .btn2:hover, .btn {
  background-color: #2f75e7;
  color: white;
}

.u-color-secondary {
  color: #d1d5d8 !important;
}

.u-bg-secondary {
  background-color: #d1d5d8;
  color: white;
}

.u-color-tint {
  color: rgb(67.8, 130.8, 233.4) !important;
}

.u-bg-tint {
  background-color: rgb(67.8, 130.8, 233.4);
  color: white;
}

.u-color-shade {
  color: rgb(42.3, 105.3, 207.9) !important;
}

.u-bg-shade {
  background-color: rgb(42.3, 105.3, 207.9);
  color: white;
}

.u-color-black, .caracteristique .bg_image .block_contenu h4, .caracteristique .bg_image .block_contenu h2, .nos_services .menu_service a .number_service, .nos_services .menu_service a, .nos_services h2, .resultat .block_blog p, .blog .block_blog p, .blog h2, .course, .service .service_contenu p, .service .service_contenu h2, .transport a p, .transport a h3, .transport a blockquote, .transport h2, .contact_bg .entete .contact_txt a, .contact_bg .entete .contact_txt, .blog_post .post_views p, .blog_post .post_views h3, .blog_post .post_views blockquote, .blog_bg .entete p, .vert .block_contenu p, .vert .block_contenu h2, .page_404 .entete p, .menu-icon a.active h3, .menu-icon a.active blockquote, .menu-icon a:hover h3, .menu-icon a:hover blockquote, .blog_nav .previous_post a, .blog_nav .next_post a, .blog_nav .previous_post, .blog_nav .next_post, nav .pagination li.active a, nav.navbar .navbar-nav li a, nav.navbar .navbar-text {
  color: black !important;
}

.u-bg-black {
  background-color: black;
  color: white;
}

.u-color-dark-gray {
  color: rgb(63.75, 63.75, 63.75) !important;
}

.u-bg-dark-gray {
  background-color: rgb(63.75, 63.75, 63.75);
  color: white;
}

.u-color-gray {
  color: rgb(127.5, 127.5, 127.5) !important;
}

.u-bg-gray {
  background-color: rgb(127.5, 127.5, 127.5);
  color: white;
}

.u-color-light-gray {
  color: rgb(191.25, 191.25, 191.25) !important;
}

.u-bg-light-gray {
  background-color: rgb(191.25, 191.25, 191.25);
  color: white;
}

.u-color-transparence {
  color: rgba(255, 255, 255, 0.95) !important;
}

.u-bg-transparence {
  background-color: rgba(255, 255, 255, 0.95);
  color: white;
}

.u-color-white, .landing__btn--form:hover, .contenu .description, .resultat .block_blog:hover p, .resultat .block_blog:hover h3, .resultat .block_blog:hover blockquote, .experts .fiche .description, .blog .block_blog:hover p, .blog .block_blog:hover h3, .blog .block_blog:hover blockquote, .transport a:hover h3, .transport a:hover blockquote, .transport a:hover p, .video .home_header_left .titre_banner, footer .copyright ul a, footer .copyright, footer .droit p, footer .droit .langue, footer .menu_service ul li a, footer .menu_service h4, footer .menu_footer ul li a, nav .pagination li a:hover, nav.navbar .navbar-nav li.dropdown .dropdown-menu li.blue a, nav.navbar .navbar-nav li.dropdown .dropdown-menu li a:hover, .btn-header-landing:hover {
  color: white !important;
}

.u-bg-white, .landing__btn:hover, .caracteristique .bg_image .block_contenu, .blog, .course, .service, .transport, .contact_bg .paddind_blanc, .blog_bg .paddind_blanc, .vert .block_contenu, .page_404 .paddind_blanc, .landingpage__navbar .btn__header:hover, .btn-header-landing, .btn-form:hover, .btn:hover {
  background-color: white;
  color: white;
}

.u-color-gris {
  color: #fbfbfb !important;
}

.u-bg-gris {
  background-color: #fbfbfb;
  color: white;
}

[class*=u-bg-] a:not(.button):hover {
  /*opacity: 0.4;*/
}

.u-display-block {
  display: block;
}

.u-display-none {
  display: none;
}

.u-display-inline {
  display: inline;
}

.u-display-inline-block, .btn-form, .btn2 {
  display: inline-block;
}

.u-display-flex {
  display: -webkit-box;
  display: -moz-box;
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.u-display-flex-md {
  display: block;
}
@media screen and (min-width: 768px) {
  .u-display-flex-md {
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.u-display-flex-lg {
  display: block;
}
@media screen and (min-width: 992px) {
  .u-display-flex-lg {
    display: -webkit-box;
    display: -moz-box;
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.u-display-block-sm {
  display: none;
}
@media screen and (min-width: 576px) {
  .u-display-block-sm {
    display: block !important;
  }
}

.u-display-block-md {
  display: none;
}
@media screen and (min-width: 768px) {
  .u-display-block-md {
    display: block !important;
  }
}

.u-display-block-lg {
  display: none;
}
@media screen and (min-width: 992px) {
  .u-display-block-lg {
    display: block !important;
  }
}

.u-display-block-xl {
  display: none;
}
@media screen and (min-width: 1200px) {
  .u-display-block-xl {
    display: block !important;
  }
}

@media screen and (min-width: 576px) {
  .u-display-none-sm {
    display: none !important;
  }
}

@media screen and (min-width: 768px) {
  .u-display-none-md {
    display: none !important;
  }
}

@media screen and (min-width: 992px) {
  .u-display-none-lg {
    display: none !important;
  }
}

@media screen and (min-width: 1200px) {
  .u-display-none-xl {
    display: none !important;
  }
}

.u-overflow-visible {
  overflow: visible;
}

.u-transition-300 {
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}

.u-transition-500 {
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.u-span-3 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-3:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .u-span-3 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .u-span-3:last-child {
    margin-right: 0;
  }
  .u-span-3:nth-child(2n) {
    margin-right: 0;
  }
  .u-span-3:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 992px) {
  .u-span-3 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 23.2317613015%;
  }
  .u-span-3:last-child {
    margin-right: 0;
  }
  .u-span-3:nth-child(2n) {
    margin-right: 2.3576515979%;
  }
  .u-span-3:nth-child(2n+1) {
    clear: none;
  }
  .u-span-3:nth-child(4n) {
    margin-right: 0;
  }
  .u-span-3:nth-child(4n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-3 {
    max-width: 23.2317613015%;
  }
}

.u-span-4 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-4:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .u-span-4 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .u-span-4:last-child {
    margin-right: 0;
  }
  .u-span-4:nth-child(2n) {
    margin-right: 0;
  }
  .u-span-4:nth-child(2n+1) {
    clear: left;
  }
}
@media screen and (min-width: 992px) {
  .u-span-4 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 31.7615656014%;
  }
  .u-span-4:last-child {
    margin-right: 0;
  }
  .u-span-4:nth-child(2n) {
    margin-right: 2.3576515979%;
  }
  .u-span-4:nth-child(2n+1) {
    clear: none;
  }
  .u-span-4:nth-child(3n) {
    margin-right: 0;
  }
  .u-span-4:nth-child(3n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-4 {
    max-width: 31.7615656014%;
  }
}

.u-span-5 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-5:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .u-span-5 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 40.2913699012%;
  }
  .u-span-5:last-child {
    margin-right: 0;
  }
  .u-span-5:nth-child(2n) {
    margin-right: 0;
  }
  .u-span-5:nth-child(2n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-5 {
    max-width: 40.2913699012%;
  }
}

.u-span-6 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-6:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .u-span-6 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 48.821174201%;
  }
  .u-span-6:last-child {
    margin-right: 0;
  }
  .u-span-6:nth-child(2n) {
    margin-right: 0;
  }
  .u-span-6:nth-child(2n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-6 {
    max-width: 48.821174201%;
  }
}

.u-span-7 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-7:last-child {
  margin-right: 0;
}
@media screen and (min-width: 768px) {
  .u-span-7 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 57.3509785009%;
  }
  .u-span-7:last-child {
    margin-right: 0;
  }
  .u-span-7:nth-child(1n) {
    margin-right: 0;
  }
  .u-span-7:nth-child(1n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-7 {
    max-width: 57.3509785009%;
  }
}

.u-span-8 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-8:last-child {
  margin-right: 0;
}
@media screen and (min-width: 992px) {
  .u-span-8 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 65.8807828007%;
  }
  .u-span-8:last-child {
    margin-right: 0;
  }
  .u-span-8:nth-child(1n) {
    margin-right: 0;
  }
  .u-span-8:nth-child(1n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-8 {
    max-width: 65.8807828007%;
  }
}

.u-span-9 {
  float: left;
  display: block;
  margin-right: 2.3576515979%;
  width: 100%;
  margin-right: 0;
}
.u-span-9:last-child {
  margin-right: 0;
}
@media screen and (min-width: 992px) {
  .u-span-9 {
    float: left;
    display: block;
    margin-right: 2.3576515979%;
    width: 74.4105871005%;
  }
  .u-span-9:last-child {
    margin-right: 0;
  }
  .u-span-9:nth-child(1n) {
    margin-right: 0;
  }
  .u-span-9:nth-child(1n+1) {
    clear: left;
  }
}

@media screen and (min-width: 768px) {
  .u-max-width-9 {
    max-width: 74.4105871005%;
  }
}

[class*=u-span-]:last-child {
  margin-right: 0;
}

@font-face {
  font-weight: normal;
  font-style: normal;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Responsive Media
 * -----------------------------------------------------------*/
[class*=responsive-media-] {
  position: relative;
  height: 0;
  overflow: hidden;
}
[class*=responsive-media-][class*="1x2"] {
  padding-top: 50%;
}
[class*=responsive-media-][class*="16x9"] {
  padding-top: 56.25%;
}
[class*=responsive-media-][class*="4x3"] {
  padding-top: 75%;
}
[class*=responsive-media-][class*="1x1"] {
  padding-top: 100%;
}
[class*=responsive-media-] iframe, [class*=responsive-media-] video, [class*=responsive-media-] object, [class*=responsive-media-] img {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

.u-clear:before, .u-container:before, .u-clear:after, .u-container:after {
  content: "";
  display: table;
}
.u-clear:after, .u-container:after {
  clear: both;
}

.u-clear-both {
  clear: both;
}

.u-container {
  max-width: 71.25rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
}
.u-container::after {
  clear: both;
  content: "";
  display: table;
}
.u-container.u-container--bleed {
  padding-left: 0;
  padding-right: 0;
}

.u-flex-wrap {
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.u-flex-wrap.u-align-items-flex-start {
  -webkit-box-align: start;
  -moz-box-align: start;
  box-align: start;
  -webkit-align-items: flex-start;
  -moz-align-items: flex-start;
  -ms-align-items: flex-start;
  -o-align-items: flex-start;
  align-items: flex-start;
  -ms-flex-align: start;
}
.u-flex-wrap.u-align-items-flex-end {
  -webkit-box-align: end;
  -moz-box-align: end;
  box-align: end;
  -webkit-align-items: flex-end;
  -moz-align-items: flex-end;
  -ms-align-items: flex-end;
  -o-align-items: flex-end;
  align-items: flex-end;
  -ms-flex-align: end;
}
.u-flex-wrap.u-align-items-center {
  -webkit-box-align: center;
  -moz-box-align: center;
  box-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  -o-align-items: center;
  align-items: center;
  -ms-flex-align: center;
}

.u-center {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.u-float-right {
  float: right;
}

.u-float-left {
  float: left;
}

.u-position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.u-hug {
  margin-top: 0;
}

.u-nudge {
  margin-top: 8px;
}

.u-push {
  margin-top: 16px;
}

.u-pad {
  padding: 16px;
}

.u-push-2x {
  margin-top: 32px;
}

.u-pad-2x {
  margin-top: 32px;
}

.u-push-3x {
  margin-top: 48px;
}

.u-pad-3x {
  margin-top: 48px;
}

.u-push-4x {
  margin-top: 64px;
}

.u-pad-4x {
  margin-top: 64px;
}

.u-push-5x {
  margin-top: 80px;
}

.u-pad-5x {
  margin-top: 80px;
}

.u-push-6x {
  margin-top: 96px;
}

.u-pad-6x {
  margin-top: 96px;
}

.u-weight-light {
  font-weight: 300;
}

.u-weight-normal {
  font-weight: 400;
}

.u-weight-medium, .contenu .description, .caracteristique .bg_image .block_contenu h4, .nos_services .service_txt, .course .row .block_contenu p, .service .service_contenu p, .transport a p, .contact_bg .entete .block_tel .phones, .equipe .course_txt p, .vert .block_contenu p, .histoire .histoire_txt p, .mission .block_lien p, .mission .mission_txt, .apropos .apropos_txt {
  font-weight: 500;
}

.u-weight-semi, .caracteristique .bg_image .block_contenu div p, .nos_services .menu_service a h3, .nos_services .menu_service a blockquote, .resultat .block_blog p, .experts .fiche .description p, .blog .block_blog p, .transport a h3, .transport a blockquote, .video .home_header_right .block_blue .texte, .video .home_header_right .block_blue .titre, .contact_bg .entete .contact_txt, .blog_bg .entete p, .vert .block_contenu .avantage p, .page_404 .entete p, .menu-icon a h3, .menu-icon a blockquote, footer .copyright, footer .droit p, footer .droit .langue, footer .menu_service ul li a, footer .menu_service h4, footer .menu_footer ul li a, .timeline ul li .content, nav .pagination li a {
  font-weight: 600;
}

.u-weight-bold, .landing__btn, .landing__section2__form input[type=submit], .nos_services .menu_service a .number_service, .resultat .block_blog h3, .resultat .block_blog blockquote, .experts .fiche .description h3, .experts .fiche .description blockquote, .blog .block_blog h3, .blog .block_blog blockquote, .service .service_contenu h2, .video .home_header_left .titre_banner, .contact_bg .entete .block_form h3, .contact_bg .entete .block_form blockquote, .blog_post .post_views p, .blog_post .post_views h3, .blog_post .post_views blockquote, .mission .block_lien h3, .mission .block_lien blockquote, .landingpage__navbar .langue, .landingpage__navbar .btn__header, .block_contact p, .blog_nav .previous_post, .blog_nav .next_post, nav.navbar .navbar-nav li.tel, nav.navbar .navbar-nav li.soumission, nav.navbar .navbar-nav li a, nav.navbar .navbar-text, .area_txt, .input_txt, .btn-header-landing, .btn2, .btn, h2, .h2 {
  font-weight: 700;
}

.u-caps, .landing__btn, .landing__section2__form input[type=submit], .nos_services .menu_service a h3, .nos_services .menu_service a blockquote, .resultat .block_blog h3, .resultat .block_blog blockquote, .experts .fiche .description h3, .experts .fiche .description blockquote, .blog .block_blog h3, .blog .block_blog blockquote, .service .service_contenu h2, .transport a h3, .transport a blockquote, .video .home_header_right .block_blue .titre, .video .home_header_left .titre_banner, .contact_bg .entete .block_form h3, .contact_bg .entete .block_form blockquote, .blog_post .post_views h3, .blog_post .post_views blockquote, .mission .block_lien h3, .mission .block_lien blockquote, .landingpage__navbar .langue, .landingpage__navbar .btn__header, .block_contact p, .menu-icon a h3, .menu-icon a blockquote, footer .copyright, footer .droit p, footer .droit .langue, footer .menu_service h4, footer .menu_footer ul li a, .blog_nav .previous_post, .blog_nav .next_post, nav.navbar .navbar-nav li a, nav.navbar .navbar-text, .area_txt, .input_txt, .btn-header-landing, .btn2, .btn, h2, .h2 {
  text-transform: uppercase;
}

.u-font-size-sm {
  font-size: 75%;
}

.u-font-size-lg {
  font-size: 125%;
}

.u-font-size-xl {
  font-size: 2rem;
  line-height: 1em;
}

.u-text-left, .blog h2, .course .row .block_contenu h2, .contact_bg .entete h2, .vert .block_contenu h2 {
  text-align: left;
}

.u-text-center, .block_contact p, h2, .h2 {
  text-align: center;
}

.u-text-right, footer .droit {
  text-align: right;
}

.u-text-shadow {
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
}

.u-format-text > ul, .u-format-text > ol {
  margin: 16px 0 0;
}
.u-format-text ul, .u-format-text ol {
  padding: 0 0 0 1em;
}
.u-format-text ul {
  list-style: none;
}
.u-format-text ul li {
  position: relative;
}
.u-format-text ul li:before {
  content: "";
  display: block;
  width: 5px;
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0.5em;
  margin-left: -1em;
}
.u-format-text ol {
  list-style: decimal;
}
.u-format-text li {
  margin-top: 0.75em;
}
.u-format-text li:first-child {
  margin-top: 0;
}
.u-format-text li li:first-child {
  margin-top: 0.75em;
}
.u-format-text li > ol {
  list-style: lower-alpha;
}
.u-format-text li li > ol {
  list-style: lower-roman;
}

/* Global
 * ------------------------------------------- */
*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

/* Media Size indicator
 *
 * Used to display the current media size.
 * See footer.php for the element.
 * ---------------------------------------- */
.media-size {
  display: block;
  position: fixed;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.25);
  padding: 5px;
  color: #bbb;
  font-size: 12px;
}
.media-size:before {
  content: "default";
}
@media screen and (min-width: 576px) {
  .media-size:before {
    content: "small";
  }
}
@media screen and (min-width: 768px) {
  .media-size:before {
    content: "medium";
  }
}
@media screen and (min-width: 992px) {
  .media-size:before {
    content: "large";
  }
}
@media screen and (min-width: 1200px) {
  .media-size:before {
    content: "xlarge";
  }
}

/* Typography
 * ------------------------------------------ */
html {
  font-size: 100%;
}

body,
button,
input,
select,
textarea {
  font-weight: 400;
  color: black;
  font-size: 1rem;
  line-height: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Raleway", sans-serif;
}

/* Headings
 * ------------------------------------------ */
h1, .h1 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 33px;
}

h2, .h2 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 25px;
}

h3, blockquote, .h3 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 1.175em;
}

h4, .h4 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 1.125em;
  font-weight: 400;
}

h5, .h5 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 0.825em;
}

h6, .h6 {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 0.75em;
}

@media (max-width: 768px) {
  h2, .h2 {
    font-size: 20px;
  }
}
@media (max-width: 576px) {
  h2, .h2 {
    font-size: 17px;
  }
}
/* Links
 * ------------------------------------------- */
a {
  color: #2f75e7;
  text-decoration: none;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  transition: all 300ms ease;
}
a:hover, a:focus, a:active {
  color: #d1d5d8;
}
a:focus {
  outline: 0;
}
a:hover, a:active {
  outline: 0;
}

/* Copy
 * ------------------------------------------ */
p {
  margin-top: 16px;
  margin-bottom: 0;
}

ul, ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin: 0 1.5em 1.5em;
}

b, strong {
  font-weight: 700;
}

dfn, cite, em, i {
  font-style: italic;
}

address {
  margin: 16px 0 0;
}

abbr, acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark, ins {
  background: #fff9c0;
  text-decoration: none;
}

hr {
  border: 0;
  height: 1px;
  margin: 16px 0 0;
  width: 100%;
}

/* Text meant only for screen readers.
 * ------------------------------------------------- */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
}
.screen-reader-text:hover, .screen-reader-text:active, .screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 0.0546875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/* HOMEPAGE */
a .home_icon_1 {
  width: 85px;
  height: 50px;
  background-image: url("../images/terrestre_blue.png");
}
a:hover .home_icon_1 {
  background-image: url("../images/terrestre_white.png");
}

a .home_icon_2 {
  width: 85px;
  height: 50px;
  background-image: url("../images/aerien_blue.png");
}
a:hover .home_icon_2 {
  background-image: url("../images/aerien_white.png");
}

a .home_icon_3 {
  width: 85px;
  height: 50px;
  background-image: url("../images/maritime_blue.png");
}
a:hover .home_icon_3 {
  background-image: url("../images/maritime_white.png");
}

a .home_icon_4 {
  width: 85px;
  height: 50px;
  background-image: url("../images/transport_blue.png");
}
a:hover .home_icon_4 {
  background-image: url("../images/transport_white.png");
}

/* TRANSPORT */
a .transport_icon_1 {
  width: 85px;
  height: 50px;
  background-image: url("../images/terrestre_gris.png");
}
a:hover .transport_icon_1, a.active .transport_icon_1 {
  background-image: url("../images/terrestre_blue.png");
}

a .transport_icon_2 {
  width: 85px;
  height: 50px;
  background-image: url("../images/aerien_gris.png");
}
a:hover .transport_icon_2, a.active .transport_icon_2 {
  background-image: url("../images/aerien_blue.png");
}

a .transport_icon_3 {
  width: 85px;
  height: 50px;
  background-image: url("../images/maritime_gris.png");
}
a:hover .transport_icon_3, a.active .transport_icon_3 {
  background-image: url("../images/maritime_blue.png");
}

a .transport_icon_4 {
  width: 85px;
  height: 50px;
  background-image: url("../images/transport_gris.png");
}
a:hover .transport_icon_4, a.active .transport_icon_4 {
  background-image: url("../images/transport_blue.png");
}

/* EXPERT */
a .expert_icon_1 {
  width: 85px;
  height: 50px;
  background-image: url("../images/admin_gris.png");
}
a:hover .expert_icon_1, a.active .expert_icon_1 {
  background-image: url("../images/admin_blue.png");
}

a .expert_icon_2 {
  width: 85px;
  height: 50px;
  background-image: url("../images/maritime_gris.png");
}
a:hover .expert_icon_2, a.active .expert_icon_2 {
  background-image: url("../images/maritime_blue.png");
}

a .expert_icon_3 {
  width: 85px;
  height: 50px;
  background-image: url("../images/entrepot_gris.png");
}
a:hover .expert_icon_3, a.active .expert_icon_3 {
  background-image: url("../images/entrepot_blue.png");
}

a .expert_icon_4 {
  width: 85px;
  height: 50px;
  background-image: url("../images/transport_gris.png");
}
a:hover .expert_icon_4, a.active .expert_icon_4 {
  background-image: url("../images/transport_blue.png");
}

.btn {
  font-size: 12px;
  padding: 0 20px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  border: 1px solid #2f75e7;
  color: white;
}
.btn i {
  margin-left: 10px;
}
.btn:hover {
  color: #2f75e7;
}

.btn2 {
  font-size: 12px;
  padding: 0 20px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  border: 1px solid white;
  color: #2f75e7;
}
.btn2 i {
  margin-left: 10px;
}
.btn2:hover {
  color: white;
  text-decoration: none;
}

.btn-form {
  font-size: 20px;
  padding: 0 10px;
  height: 40px;
  border-radius: 5px;
  line-height: 40px;
  border: 1px solid #2f75e7;
  color: white;
}
.btn-form:hover {
  color: #2f75e7;
  text-decoration: none;
}

.btn-header-landing {
  font-size: 12px;
  padding: 0 20px;
  height: 40px;
  border-radius: 20px;
  line-height: 40px;
  border: 1px solid #2f75e7;
  color: #2f75e7;
}
.btn-header-landing i {
  margin-left: 10px;
}
.btn-header-landing:hover {
  border: 1px solid white;
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 86px;
  height: 86px;
  margin-top: -43px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  z-index: 2;
  opacity: 0.6;
}
.slick-prev:focus, .slick-next:focus {
  outline: none;
}
.slick-prev:hover, .slick-next:hover {
  opacity: 1;
}

.slick-next {
  right: 40px;
  background: url(../images/right_btn.png) no-repeat !important;
  background-size: contain !important;
}

.slick-prev {
  left: 40px;
  background: url(../images/left_btn.png) no-repeat !important;
  background-size: contain !important;
}

@media (max-width: 768px) {
  .slick-prev, .slick-next {
    width: 60px;
    height: 60px;
    margin-top: -30px;
  }
  .slick-next {
    right: 20px;
  }
  .slick-prev {
    left: 20px;
  }
}
@media (max-width: 576px) {
  .btn {
    font-size: 10px;
    padding: 0 10px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
  }
  .btn i {
    margin-left: 5px;
  }
  .btn2 {
    font-size: 10px;
    padding: 0 10px;
    height: 30px;
    border-radius: 15px;
    line-height: 30px;
  }
  .btn2 i {
    margin-left: 5px;
  }
  .slick-next {
    right: 5px;
  }
  .slick-prev {
    left: 5px;
  }
}
.input_txt {
  width: 100%;
  height: 40px;
  border-radius: 20px;
  padding-left: 20px;
  border: 1px solid #bcc0c4;
  font-size: 12px;
}
.input_txt:focus {
  outline: none;
  border: 1px solid #2f75e7;
}

.area_txt {
  width: 100%;
  border-radius: 20px;
  padding-left: 20px;
  padding-top: 15px;
  border: 1px solid #bcc0c4;
  font-size: 12px;
  resize: none;
}
.area_txt:focus {
  outline: none;
  border: 1px solid #2f75e7;
}

input.btn {
  width: 100%;
}
input.btn:after {
  content: "\f178";
  font-family: "fontAwesome";
  margin-left: 5px;
}

span.wpcf7-not-valid-tip {
  font-size: 10px;
  margin-top: 5px;
  margin-left: 10px;
}

div.wpcf7-validation-errors {
  border: 0;
  color: #f00;
}

div.wpcf7-response-output {
  margin: 0;
  padding: 0 10px;
}

.form-inline .input-group {
  margin: 0 auto 60px;
}
.form-inline .input-group .form-control {
  border-radius: 5px;
  background: transparent;
}
.form-inline .input-group .input-group-btn {
  margin-left: 10px;
}

nav.navbar {
  padding: 20px 0;
}
nav.navbar .navbar-brand {
  position: relative;
  z-index: 2;
  width: auto;
  display: table;
}
nav.navbar .navbar-brand img {
  height: 50px;
}
nav.navbar .navbar-text {
  font-size: 12px;
  width: 290px;
}
nav.navbar .navbar-nav {
  margin-top: 27px;
}
nav.navbar .navbar-nav li {
  padding: 0 10px;
}
nav.navbar .navbar-nav li a {
  font-size: 13px;
  padding: 20px 0;
  border-bottom: 2px solid white;
}
nav.navbar .navbar-nav li a:hover {
  border-bottom: 2px solid #2f75e7;
  text-decoration: none;
}
nav.navbar .navbar-nav li.blue a {
  background-color: rgba(47, 117, 231, 0.6);
}
nav.navbar .navbar-nav li.soumission {
  border: 2px solid #2f75e7;
  font-size: 13px;
  height: 40px;
  line-height: 37px;
  border-radius: 20px;
  margin-top: -13px;
  margin-right: 10px;
}
nav.navbar .navbar-nav li.soumission a {
  border-bottom: 0;
}
nav.navbar .navbar-nav li.soumission a:hover {
  border-bottom: 0;
}
nav.navbar .navbar-nav li.tel {
  border: 2px solid #2f75e7;
  font-size: 13px;
  height: 40px;
  line-height: 37px;
  border-radius: 20px;
  margin-top: -13px;
}
nav.navbar .navbar-nav li.mobile {
  display: none;
}
nav.navbar .navbar-nav li.current-menu-item a {
  border-bottom: 2px solid #2f75e7;
}
nav.navbar .navbar-nav li.dropdown {
  top: -12px;
  padding: 0.25rem 0.75rem;
}
nav.navbar .navbar-nav li.dropdown a {
  padding: 0;
  margin-bottom: 0;
  border-bottom: 0;
}
nav.navbar .navbar-nav li.dropdown a span {
  background-image: url(../images/menu.png);
  height: 21px;
  width: 28px;
  margin: 5px 0;
  display: inline-block;
}
nav.navbar .navbar-nav li.dropdown a::after {
  display: none;
}
nav.navbar .navbar-nav li.dropdown:hover, nav.navbar .navbar-nav li.dropdown.show {
  border-radius: 3px;
}
nav.navbar .navbar-nav li.dropdown:hover a, nav.navbar .navbar-nav li.dropdown.show a {
  border-bottom: 0;
}
nav.navbar .navbar-nav li.dropdown:hover a span, nav.navbar .navbar-nav li.dropdown.show a span {
  background-image: url(../images/menu_ov.png);
}
nav.navbar .navbar-nav li.dropdown .dropdown-menu {
  left: inherit;
  right: 0;
  width: 200px;
  padding: 0;
}
nav.navbar .navbar-nav li.dropdown .dropdown-menu li {
  padding: 0;
  position: relative;
}
nav.navbar .navbar-nav li.dropdown .dropdown-menu li a {
  border-bottom: 0;
  padding: 10px 20px;
  display: block;
}
nav.navbar .navbar-nav li.dropdown .dropdown-menu li a:hover {
  border-bottom: 0;
  background: #000;
}
.single-expert nav ul li.menu-item-object-expert a,
.single-service nav ul li.menu-item-object-service a,
.single-transport nav ul li.menu-item-object-transport a {
  border-bottom: 2px solid #2f75e7;
}

.navbar-light .navbar-toggler {
  border-color: white;
}
.navbar-light .navbar-toggler .navbar-toggler-icon {
  background-image: url(../images/menu.png);
  height: 21px;
  width: 28px;
  margin: 5px 0;
}
.navbar-light .navbar-toggler:hover {
  border-color: #2f75e7;
}
.navbar-light .navbar-toggler:hover .navbar-toggler-icon {
  background-image: url(../images/menu_ov.png);
}
.navbar-light .navbar-toggler:focus {
  outline: none;
}

@media (max-width: 1200px) {
  nav.navbar .navbar-nav li {
    padding: 0 5px;
  }
  nav.navbar .navbar-nav li.soumission {
    margin-right: 5px;
  }
}
@media (max-width: 992px) {
  .collapse.show, .collapsing {
    display: block;
    border-top: 1px solid #ccc;
    position: absolute;
    top: 98px;
    z-index: 99;
    width: 100%;
    background: rgba(255, 255, 255, 0.9);
  }
  nav.navbar .navbar-brand {
    width: 75%;
  }
  nav.navbar .navbar-text {
    position: absolute;
    width: 325px;
    padding-left: 145px;
    top: 20px;
  }
  nav.navbar .navbar-nav {
    margin-top: 0;
  }
  nav.navbar .navbar-nav li {
    padding: 0;
  }
  nav.navbar .navbar-nav li a {
    border-bottom: 0;
    padding: 8px 20px;
    display: block;
  }
  nav.navbar .navbar-nav li a:hover {
    border-bottom: 0;
    background: white;
  }
  nav.navbar .navbar-nav li.tel {
    display: none;
  }
  nav.navbar .navbar-nav li.soumission {
    border: 0;
    font-size: 13px;
    height: inherit;
    line-height: inherit;
    border-radius: 0;
    margin-top: inherit;
  }
  nav.navbar .navbar-nav li.mobile {
    display: block;
  }
  nav.navbar .navbar-nav li.current-menu-item a {
    border-bottom: 0;
  }
  nav.navbar .navbar-nav li.dropdown {
    display: none;
  }
  nav.navbar .single-expert nav ul li.menu-item-object-expert a,
  nav.navbar .single-transport nav ul li.menu-item-object-transport a {
    border-bottom: 0;
  }
}
@media (max-width: 768px) {
  .collapse.show, .collapsing {
    top: 58px;
  }
  nav.navbar {
    padding: 0;
  }
  nav.navbar .navbar-text {
    top: 8px;
  }
  .navbar-light .navbar-toggler {
    margin-top: 8px;
  }
}
@media (max-width: 576px) {
  nav.navbar .navbar-text {
    display: none;
  }
}
nav.navbar .navbar-nav li.dropdown .dropdown-menu li::after {
  content: "";
  left: 20px;
  height: 1px;
  width: 160px;
  bottom: 0px;
  position: absolute;
  background-color: #d9d9d9;
}

nav.navbar .navbar-nav li.dropdown .dropdown-menu li.blue::after {
  background-color: #659eeb;
}

nav.navbar .navbar-nav li.dropdown .dropdown-menu li:last-child::after,
nav.navbar .navbar-nav li.dropdown .dropdown-menu li:hover::after {
  background-color: transparent;
}

nav .pagination {
  margin-bottom: 100px;
}
nav .pagination li {
  padding: 0px;
}
nav .pagination li a {
  color: #a7a6a7;
  font-size: 14px;
  padding: 10px;
}
nav .pagination li a:hover {
  text-decoration: none;
}
.blog_nav {
  margin-bottom: 200px;
}
.blog_nav .previous_post, .blog_nav .next_post {
  font-size: 11px;
  margin-bottom: 20px;
}
.blog_nav .previous_post i, .blog_nav .next_post i {
  margin: 0 10px;
  font-size: 13px;
}
.blog_nav .previous_post a, .blog_nav .next_post a {
  text-decoration: underline;
}
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.timeline {
  color: #fff;
}
.timeline ul {
  padding: 70px 0 170px;
}
.timeline ul li {
  background: white;
  position: relative;
  margin: 0 auto;
  width: 5px;
  padding-bottom: 40px;
  list-style-type: none;
}
.timeline ul li:last-child {
  padding-bottom: 7px;
}
.timeline ul li .hidden {
  opacity: 0;
}
.timeline ul li .content {
  background: #2863c4;
  position: relative;
  top: 7px;
  width: 450px;
  padding: 30px;
  font-size: 13px;
  border-radius: 5px;
}
.timeline ul li .content time {
  color: white;
  opacity: 0.5;
}
.timeline ul li .content p {
  margin-top: 40px;
}
.timeline ul li .content:before {
  content: "";
  background: white;
  position: absolute;
  top: 30px;
  width: 38px;
  height: 5px;
}
.timeline ul li:nth-child(odd) .content {
  left: 50px;
  background: #2863c4;
  background: -webkit-linear-gradient(-45deg, #2863c4, #2863c4);
  background: linear-gradient(-45deg, #2863c4, #2863c4);
}
.timeline ul li:nth-child(odd) .content:before {
  left: -50px;
}
.timeline ul li:nth-child(even) .content {
  left: calc(-450px - 45px);
  background: #2863c4;
  background: -webkit-linear-gradient(45deg, #2863c4, #2863c4);
  background: linear-gradient(45deg, #2863c4, #2863c4);
}
.timeline ul li:nth-child(even) .content:before {
  right: -50px;
}

/* -------------------------
   ----- Media Queries -----
   ------------------------- */
@media screen and (max-width: 1200px) {
  .timeline ul li .content {
    width: 41vw;
  }
  .timeline ul li:nth-child(even) .content {
    left: calc(-41vw - 45px);
  }
}
@media screen and (max-width: 768px) {
  .timeline ul li {
    margin-left: 20px;
  }
  .timeline ul li .content {
    width: 60vw;
    padding: 15px;
  }
  .timeline ul li .content h2 {
    text-align: initial;
  }
  .timeline ul li:nth-child(even) .content {
    left: 50px;
    background: #2863c4;
    background: -webkit-linear-gradient(-45deg, #2863c4, #2863c4);
    background: linear-gradient(-45deg, #2863c4, #2863c4);
  }
  .timeline ul li:nth-child(even) .content:before {
    left: -45px;
    width: 33px;
  }
}
.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after, .twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
  content: " ";
  display: block;
  background: white;
  position: absolute;
  z-index: 30;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:before, .twentytwenty-horizontal .twentytwenty-handle:after {
  width: 3px;
  height: 9999px;
  left: 50%;
  margin-left: -1.5px;
}

.twentytwenty-vertical .twentytwenty-handle:before, .twentytwenty-vertical .twentytwenty-handle:after {
  width: 9999px;
  height: 3px;
  top: 50%;
  margin-top: -1.5px;
}

.twentytwenty-before-label, .twentytwenty-after-label, .twentytwenty-overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}

.twentytwenty-before-label, .twentytwenty-after-label, .twentytwenty-overlay {
  -webkit-transition-duration: 0.5s;
  -moz-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.twentytwenty-before-label, .twentytwenty-after-label {
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  transition-property: opacity;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
  color: white;
  font-size: 13px;
  letter-spacing: 0.1em;
}

.twentytwenty-before-label:before, .twentytwenty-after-label:before {
  position: absolute;
  background: rgba(255, 255, 255, 0.2);
  line-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}

.twentytwenty-horizontal .twentytwenty-before-label:before, .twentytwenty-horizontal .twentytwenty-after-label:before {
  top: 50%;
  margin-top: -19px;
}

.twentytwenty-vertical .twentytwenty-before-label:before, .twentytwenty-vertical .twentytwenty-after-label:before {
  left: 50%;
  margin-left: -45px;
  text-align: center;
  width: 90px;
}

.twentytwenty-left-arrow, .twentytwenty-right-arrow, .twentytwenty-up-arrow, .twentytwenty-down-arrow {
  width: 0;
  height: 0;
  border: 6px inset transparent;
  position: absolute;
}

.twentytwenty-left-arrow, .twentytwenty-right-arrow {
  top: 50%;
  margin-top: -6px;
}

.twentytwenty-up-arrow, .twentytwenty-down-arrow {
  left: 50%;
  margin-left: -6px;
}

.twentytwenty-container {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  z-index: 0;
  overflow: hidden;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.twentytwenty-container img {
  max-width: 100%;
  position: absolute;
  top: 0;
  display: block;
}

.twentytwenty-container.active .twentytwenty-overlay, .twentytwenty-container.active :hover.twentytwenty-overlay {
  background: rgba(0, 0, 0, 0);
}

.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active .twentytwenty-overlay .twentytwenty-after-label, .twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-before-label,
.twentytwenty-container.active :hover.twentytwenty-overlay .twentytwenty-after-label {
  opacity: 0;
}

.twentytwenty-container * {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.twentytwenty-before-label {
  opacity: 0;
}

.twentytwenty-before-label:before {
  content: "Before";
}

.twentytwenty-after-label {
  opacity: 0;
}

.twentytwenty-after-label:before {
  content: "After";
}

.twentytwenty-horizontal .twentytwenty-before-label:before {
  left: 10px;
}

.twentytwenty-horizontal .twentytwenty-after-label:before {
  right: 10px;
}

.twentytwenty-vertical .twentytwenty-before-label:before {
  top: 10px;
}

.twentytwenty-vertical .twentytwenty-after-label:before {
  bottom: 10px;
}

.twentytwenty-overlay {
  -webkit-transition-property: background;
  -moz-transition-property: background;
  transition-property: background;
  background: rgba(0, 0, 0, 0);
  z-index: 25;
}

.twentytwenty-overlay:hover {
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.twentytwenty-overlay:hover .twentytwenty-after-label {
  opacity: 1;
}

.twentytwenty-overlay:hover .twentytwenty-before-label {
  opacity: 1;
}

.twentytwenty-before {
  z-index: 20;
}

.twentytwenty-after {
  z-index: 10;
}

.twentytwenty-handle {
  height: 38px;
  width: 38px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -22px;
  margin-top: -22px;
  border: 3px solid white;
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  border-radius: 1000px;
  -webkit-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0px 0px 12px rgba(51, 51, 51, 0.5);
  z-index: 40;
  cursor: pointer;
}

.twentytwenty-horizontal .twentytwenty-handle:before {
  bottom: 50%;
  margin-bottom: 22px;
  -webkit-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0 3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-horizontal .twentytwenty-handle:after {
  top: 50%;
  margin-top: 22px;
  -webkit-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 0 -3px 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-vertical .twentytwenty-handle:before {
  left: 50%;
  margin-left: 22px;
  -webkit-box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: 3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-vertical .twentytwenty-handle:after {
  right: 50%;
  margin-right: 22px;
  -webkit-box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  -moz-box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
  box-shadow: -3px 0 0 white, 0px 0px 12px rgba(51, 51, 51, 0.5);
}

.twentytwenty-left-arrow {
  border-right: 6px solid white;
  left: 50%;
  margin-left: -17px;
}

.twentytwenty-right-arrow {
  border-left: 6px solid white;
  right: 50%;
  margin-right: -17px;
}

.twentytwenty-up-arrow {
  border-bottom: 6px solid white;
  top: 50%;
  margin-top: -17px;
}

.twentytwenty-down-arrow {
  border-top: 6px solid white;
  bottom: 50%;
  margin-bottom: -17px;
}

.banner {
  position: relative;
  max-height: 60vh;
  overflow: hidden;
  margin-top: 95px;
}
.banner .img-fluid {
  width: 100%;
}

.division {
  position: relative;
}

.diagonal_blanc {
  border-bottom: 125px solid #fff;
  border-left: 100vw solid transparent;
  position: absolute;
  bottom: 0;
}

.diagonal_blanc_right {
  border-bottom: 125px solid #fff;
  border-right: 100vw solid transparent;
  position: absolute;
  bottom: 0;
}

.diagonal_blanc_top {
  border-top: 125px solid #fff;
  border-left: 100vw solid transparent;
  position: absolute;
  top: 0;
}

.diagonal_bleu {
  border-bottom: 125px solid #2f75e7;
  border-left: 100vw solid transparent;
  position: absolute;
  bottom: 0;
}

.diagonal_bleu_top {
  border-top: 125px solid #2f75e7;
  border-right: 100vw solid transparent;
  position: absolute;
  top: 0;
}

.diagonal_gris {
  border-bottom: 125px solid #fbfbfb;
  border-left: 100vw solid transparent;
  position: absolute;
  bottom: 0;
}

@media (max-width: 992px) {
  .diagonal_blanc {
    border-bottom: 100px solid #fff;
  }
  .diagonal_blanc_right {
    border-bottom: 100px solid #fff;
  }
  .diagonal_blanc_top {
    border-top: 100px solid #fff;
  }
  .diagonal_bleu {
    border-bottom: 100px solid #2f75e7;
  }
  .diagonal_bleu_top {
    border-top: 100px solid #2f75e7;
  }
  .diagonal_gris {
    border-bottom: 100px solid #fbfbfb;
  }
}
@media (max-width: 768px) {
  .banner {
    margin-top: 55px;
  }
  .diagonal_blanc {
    border-bottom: 70px solid #fff;
  }
  .diagonal_blanc_right {
    border-bottom: 70px solid #fff;
  }
  .diagonal_blanc_top {
    border-top: 70px solid #fff;
  }
  .diagonal_bleu {
    border-bottom: 70px solid #2f75e7;
  }
  .diagonal_bleu_top {
    border-top: 70px solid #2f75e7;
  }
  .diagonal_gris {
    border-bottom: 70px solid #fbfbfb;
  }
}
@media (max-width: 576px) {
  .diagonal_blanc {
    border-bottom: 50px solid #fff;
  }
  .diagonal_blanc_right {
    border-bottom: 50px solid #fff;
  }
  .diagonal_blanc_top {
    border-top: 50px solid #fff;
  }
  .diagonal_bleu {
    border-bottom: 50px solid #2f75e7;
  }
  .diagonal_bleu_top {
    border-top: 50px solid #2f75e7;
  }
  .diagonal_gris {
    border-bottom: 50px solid #fbfbfb;
  }
}
footer {
  background-color: #1f2e39;
  width: 100%;
  padding: 40px 0;
  position: relative;
}
footer .menu_footer {
  margin-bottom: 30px;
}
footer .menu_footer ul li {
  margin-bottom: 22px;
}
footer .menu_footer ul li a {
  font-size: 12px;
}
footer .menu_service {
  margin-bottom: 30px;
}
footer .menu_service h4 {
  font-size: 12px;
  margin: 0 0 22px;
}
footer .menu_service ul li {
  margin-bottom: 10px;
}
footer .menu_service ul li a {
  font-size: 12px;
}
footer .droit .langue {
  font-size: 11px;
  position: relative;
  top: -5px;
}
footer .droit p {
  font-size: 12px;
  margin: 25px 0 20px;
}
footer .copyright {
  opacity: 0.2;
  font-size: 11px;
  margin: 40px 0 10px;
}
footer .copyright ul {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
}
footer .copyright ul a {
  text-transform: none;
}

@media (max-width: 1200px) {
  footer .droit {
    text-align: left;
  }
}
@media (max-width: 576px) {
  footer .copyright {
    margin: 20px 0 0;
  }
}
header {
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: white;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.admin-bar header {
  padding-top: 30px;
}

.menu-icon {
  padding: 35px 0 90px;
}
.menu-icon h2 {
  padding-bottom: 60px;
}
.menu-icon a {
  padding: 25px 15px 15px;
  margin: 0 auto 30px;
  border-bottom: 4px solid white;
  max-width: 180px;
}
.menu-icon a div {
  margin: auto;
}
.menu-icon a h3, .menu-icon a blockquote {
  font-size: 15px;
  color: #ccc;
  height: 50px;
}
.menu-icon a:hover {
  text-decoration: none;
  border-bottom: 4px solid #2f75e7;
}
.menu-icon a.active {
  border-bottom: 4px solid #2f75e7;
}
@media (max-width: 768px) {
  .menu-icon {
    padding: 35px 0 60px;
  }
  .menu-icon h2 {
    padding-bottom: 60px;
  }
  .menu-icon a {
    padding: 15px;
  }
}
@media (max-width: 576px) {
  .menu-icon {
    padding: 35px 0 25px;
  }
}
/* ------------------------------------------------------------------------
	This you can edit.
------------------------------------------------------------------------- */
/* ----------------------------------
	Default Theme
----------------------------------- */
div.pp_default .pp_top,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_right,
div.pp_default .pp_bottom,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right {
  height: 13px;
}

div.pp_default .pp_top .pp_left {
  background: url(../images/prettyPhoto/default/sprite.png) -78px -93px no-repeat;
} /* Top left corner */
div.pp_default .pp_top .pp_middle {
  background: url(../images/prettyPhoto/default/sprite_x.png) top left repeat-x;
} /* Top pattern/color */
div.pp_default .pp_top .pp_right {
  background: url(../images/prettyPhoto/default/sprite.png) -112px -93px no-repeat;
} /* Top right corner */
div.pp_default .pp_content .ppt {
  color: #f8f8f8;
}

div.pp_default .pp_content_container .pp_left {
  background: url(../images/prettyPhoto/default/sprite_y.png) -7px 0 repeat-y;
  padding-left: 13px;
}

div.pp_default .pp_content_container .pp_right {
  background: url(../images/prettyPhoto/default/sprite_y.png) top right repeat-y;
  padding-right: 13px;
}

div.pp_default .pp_content {
  background-color: #fff;
} /* Content background */
div.pp_default .pp_next:hover {
  background: url(../images/prettyPhoto/default/sprite_next.png) center right no-repeat;
  cursor: pointer;
} /* Next button */
div.pp_default .pp_previous:hover {
  background: url(../images/prettyPhoto/default/sprite_prev.png) center left no-repeat;
  cursor: pointer;
} /* Previous button */
div.pp_default .pp_expand {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -29px no-repeat;
  cursor: pointer;
  width: 28px;
  height: 28px;
} /* Expand button */
div.pp_default .pp_expand:hover {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -56px no-repeat;
  cursor: pointer;
} /* Expand button hover */
div.pp_default .pp_contract {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -84px no-repeat;
  cursor: pointer;
  width: 28px;
  height: 28px;
} /* Contract button */
div.pp_default .pp_contract:hover {
  background: url(../images/prettyPhoto/default/sprite.png) 0 -113px no-repeat;
  cursor: pointer;
} /* Contract button hover */
div.pp_default .pp_close {
  width: 30px;
  height: 30px;
  background: url(../images/prettyPhoto/default/sprite.png) 2px 1px no-repeat;
  cursor: pointer;
} /* Close button */
div.pp_default #pp_full_res .pp_inline {
  color: #000;
}

div.pp_default .pp_gallery ul li a {
  background: url(../images/prettyPhoto/default/default_thumb.png) center center #f8f8f8;
  border: 1px solid #aaa;
}

div.pp_default .pp_gallery ul li a:hover,
div.pp_default .pp_gallery ul li.selected a {
  border-color: #fff;
}

div.pp_default .pp_social {
  margin-top: 7px;
  display: none;
}

div.pp_default .pp_gallery a.pp_arrow_previous,
div.pp_default .pp_gallery a.pp_arrow_next {
  position: static;
  left: auto;
}

div.pp_default .pp_nav .pp_play,
div.pp_default .pp_nav .pp_pause {
  background: url(../images/prettyPhoto/default/sprite.png) -51px 1px no-repeat;
  height: 30px;
  width: 30px;
}

div.pp_default .pp_nav .pp_pause {
  background-position: -51px -29px;
}

div.pp_default .pp_details {
  position: relative;
}

div.pp_default a.pp_arrow_previous,
div.pp_default a.pp_arrow_next {
  background: url(../images/prettyPhoto/default/sprite.png) -31px -3px no-repeat;
  height: 20px;
  margin: 4px 0 0 0;
  width: 20px;
}

div.pp_default a.pp_arrow_next {
  left: 52px;
  background-position: -82px -3px;
} /* The next arrow in the bottom nav */
div.pp_default .pp_content_container .pp_details {
  margin-top: 5px;
}

div.pp_default .pp_nav {
  clear: none;
  height: 30px;
  width: 110px;
  position: relative;
}

div.pp_default .pp_nav .currentTextHolder {
  font-family: Georgia;
  font-style: italic;
  color: #999;
  font-size: 11px;
  left: 75px;
  line-height: 25px;
  margin: 0;
  padding: 0 0 0 10px;
  position: absolute;
  top: 2px;
}

div.pp_default .pp_close:hover, div.pp_default .pp_nav .pp_play:hover, div.pp_default .pp_nav .pp_pause:hover, div.pp_default .pp_arrow_next:hover, div.pp_default .pp_arrow_previous:hover {
  opacity: 0.7;
}

div.pp_default .pp_description {
  font-size: 11px;
  font-weight: bold;
  line-height: 14px;
  margin: 5px 50px 5px 0;
}

div.pp_default .pp_bottom .pp_left {
  background: url(../images/prettyPhoto/default/sprite.png) -78px -127px no-repeat;
} /* Bottom left corner */
div.pp_default .pp_bottom .pp_middle {
  background: url(../images/prettyPhoto/default/sprite_x.png) bottom left repeat-x;
} /* Bottom pattern/color */
div.pp_default .pp_bottom .pp_right {
  background: url(../images/prettyPhoto/default/sprite.png) -112px -127px no-repeat;
} /* Bottom right corner */
div.pp_default .pp_loaderIcon {
  background: url(../images/prettyPhoto/default/loader.gif) center center no-repeat;
} /* Loader icon */
/* ------------------------------------------------------------------------
	DO NOT CHANGE
------------------------------------------------------------------------- */
div.pp_pic_holder a:focus {
  outline: none;
}

div.pp_overlay {
  background: #000;
  display: none;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9500;
}

div.pp_pic_holder {
  display: none;
  position: absolute;
  width: 100px;
  z-index: 10000;
}

.pp_top {
  height: 20px;
  position: relative;
}

* html .pp_top {
  padding: 0 20px;
}

.pp_top .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.pp_top .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

* html .pp_top .pp_middle {
  left: 0;
  position: static;
}

.pp_top .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.pp_content {
  height: 40px;
  min-width: 40px;
}

* html .pp_content {
  width: 40px;
}

.pp_fade {
  display: none;
}

.pp_content_container {
  position: relative;
  text-align: left;
  width: 100%;
}

.pp_content_container .pp_left {
  padding-left: 20px;
}

.pp_content_container .pp_right {
  padding-right: 20px;
}

.pp_content_container .pp_details {
  float: left;
  margin: 10px 0 2px 0;
}

.pp_description {
  display: none;
  margin: 0;
}

.pp_social {
  float: left;
  margin: 0;
}

.pp_social .facebook {
  float: left;
  margin-left: 5px;
  width: 55px;
  overflow: hidden;
}

.pp_social .twitter {
  float: left;
}

.pp_nav {
  clear: right;
  float: left;
  margin: 3px 10px 0 0;
}

.pp_nav p {
  float: left;
  margin: 2px 4px;
  white-space: nowrap;
}

.pp_nav .pp_play,
.pp_nav .pp_pause {
  float: left;
  margin-right: 4px;
  text-indent: -10000px;
}

a.pp_arrow_previous,
a.pp_arrow_next {
  display: block;
  float: left;
  height: 15px;
  margin-top: 3px;
  overflow: hidden;
  text-indent: -10000px;
  width: 14px;
}

.pp_hoverContainer {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2000;
}

.pp_gallery {
  display: none;
  left: 50%;
  margin-top: -50px;
  position: absolute;
  z-index: 10000;
}

.pp_gallery div {
  float: left;
  overflow: hidden;
  position: relative;
}

.pp_gallery ul {
  float: left;
  height: 35px;
  margin: 0 0 0 5px;
  padding: 0;
  position: relative;
  white-space: nowrap;
}

.pp_gallery ul a {
  border: 1px #000 solid;
  border: 1px rgba(0, 0, 0, 0.5) solid;
  display: block;
  float: left;
  height: 33px;
  overflow: hidden;
}

.pp_gallery ul a:hover,
.pp_gallery li.selected a {
  border-color: #fff;
}

.pp_gallery ul a img {
  border: 0;
}

.pp_gallery li {
  display: block;
  float: left;
  margin: 0 5px 0 0;
  padding: 0;
}

.pp_gallery li.default a {
  background: url(../images/prettyPhoto/facebook/default_thumbnail.gif) 0 0 no-repeat;
  display: block;
  height: 33px;
  width: 50px;
}

.pp_gallery li.default a img {
  display: none;
}

.pp_gallery .pp_arrow_previous,
.pp_gallery .pp_arrow_next {
  margin-top: 7px !important;
}

a.pp_next {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: right;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
}

a.pp_previous {
  background: url(../images/prettyPhoto/light_rounded/btnNext.png) 10000px 10000px no-repeat;
  display: block;
  float: left;
  height: 100%;
  text-indent: -10000px;
  width: 49%;
}

a.pp_expand,
a.pp_contract {
  cursor: pointer;
  display: none;
  height: 20px;
  position: absolute;
  right: 30px;
  text-indent: -10000px;
  top: 10px;
  width: 20px;
  z-index: 20000;
}

a.pp_close {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  line-height: 22px;
  text-indent: -10000px;
}

.pp_bottom {
  height: 20px;
  position: relative;
}

* html .pp_bottom {
  padding: 0 20px;
}

.pp_bottom .pp_left {
  height: 20px;
  left: 0;
  position: absolute;
  width: 20px;
}

.pp_bottom .pp_middle {
  height: 20px;
  left: 20px;
  position: absolute;
  right: 20px;
}

* html .pp_bottom .pp_middle {
  left: 0;
  position: static;
}

.pp_bottom .pp_right {
  height: 20px;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
}

.pp_loaderIcon {
  display: block;
  height: 24px;
  left: 50%;
  margin: -12px 0 0 -12px;
  position: absolute;
  top: 50%;
  width: 24px;
}

#pp_full_res {
  line-height: 1 !important;
}

#pp_full_res .pp_inline {
  text-align: left;
}

#pp_full_res .pp_inline p {
  margin: 0 0 15px 0;
}

div.ppt {
  color: #fff;
  display: none;
  font-size: 17px;
  margin: 0 0 5px 15px;
  z-index: 9999;
}

.block_contact {
  padding: 3vw 0;
  -webkit-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 5px rgba(0, 0, 0, 0.1);
}
.block_contact p {
  font-size: 25px;
  margin: 0;
  line-height: 30px;
}
.block_contact .btn2 {
  margin-top: 45px;
}

@media (max-width: 1200px) {
  .block_contact {
    padding: 35px 0;
  }
}
@media (max-width: 992px) {
  .block_contact {
    padding: 20px 0;
  }
  .block_contact .btn2 {
    margin-top: 30px;
  }
}
@media (max-width: 768px) {
  .block_contact {
    padding: 20px 0;
  }
  .block_contact p {
    font-size: 18px;
    line-height: 20px;
  }
  .block_contact .btn2 {
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .block_contact {
    padding: 10px 0;
  }
  .block_contact p {
    font-size: 13px;
    line-height: 20px;
  }
  .block_contact .btn2 {
    margin-top: 15px;
  }
}
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.landingpage {
  background-color: #2f75e7;
  border-bottom: 0px solid #ccc;
  position: relative;
  padding: 1.875rem 0 3.75rem 0;
}
.landingpage--dark {
  background-color: #1f2e39;
  border-top: 1px solid #ccc;
}
.landingpage .row {
  align-items: center;
}
.landingpage .slogan {
  display: none;
  width: 250px;
  vertical-align: middle;
  padding-left: 2rem;
  font-size: 14px;
}
@media (min-width: 992px) {
  .landingpage .slogan {
    display: inline-block;
  }
  .landingpage .slogan--footer {
    display: none;
  }
}
@media (min-width: 768px) {
  .landingpage__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
}
@media (min-width: 992px) {
  .landingpage__navbar {
    flex-wrap: nowrap;
  }
}
.landingpage__navbar__hidden {
  display: none;
}
@media (min-width: 768px) {
  .landingpage__navbar__hidden {
    display: block;
  }
}
.landingpage__navbar .btn__header {
  display: inline-block;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  line-height: 20px;
  min-height: 40px;
  border: 2px solid white;
  background-color: transparent;
  color: white;
  margin: 0.5em 0.5em 0.5em 0;
}
.landingpage__navbar .btn__header i {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  padding-right: 0.5rem;
}
.landingpage__navbar .btn__header:hover {
  border: 2px solid white;
  color: #2f75e7;
  text-decoration: none;
}
.landingpage__navbar .btn__header:hover i {
  color: #2f75e7;
}
.landingpage__navbar .btn__header--white {
  background-color: white;
  color: #2f75e7;
}
.landingpage__navbar .btn__header--white:hover {
  background-color: #2f75e7;
  color: white;
  text-decoration: none;
}
.landingpage__navbar .btn__header--white:hover i {
  color: #2f75e7;
}
@media (min-width: 1200px) {
  .landingpage__navbar .btn__header {
    margin: 0;
  }
}
@media (min-width: 992px) {
  .landingpage__navbar .btn__header {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .landingpage__navbar .btn__header {
    font-size: 16px;
  }
}
.landingpage__navbar li:last-child {
  justify-self: end;
}
.landingpage__navbar .langue {
  color: white;
}
@media (min-width: 992px) {
  .landingpage__navbar .langue {
    font-size: 12px;
  }
}
@media (min-width: 1200px) {
  .landingpage__navbar .langue {
    font-size: 16px;
  }
}

.page_404 {
  position: relative;
  padding-top: 25%;
  background-size: cover;
  background-position: top center;
  margin-top: 95px;
}
.page_404 .entete {
  padding: 60px;
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
}
.page_404 .entete h2 {
  margin: 0 0 40px;
}
.page_404 .entete p {
  opacity: 0.7;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 30px;
}
.page_404 .paddind_blanc {
  height: 200px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

@media (max-width: 768px) {
  .page_404 {
    padding-top: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 40px;
  }
  .page_404 .entete {
    margin-bottom: 20px;
  }
  .page_404 .entete h2 {
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .page_404 .entete {
    padding: 20px;
    margin-bottom: 20px;
  }
}
.apropos {
  padding-bottom: 50px;
}
.apropos h2 {
  padding-bottom: 80px;
}
.apropos .apropos_txt {
  font-size: 13px;
  color: #626d75;
  line-height: 25px;
}
.apropos .apropos_txt p {
  margin: 0 0 30px;
}
.apropos .apropos_txt ul {
  padding-left: 30px;
  list-style-type: disc;
}

.mission {
  padding-bottom: 200px;
}
.mission h2 {
  padding-bottom: 80px;
}
.mission .mission_txt {
  font-size: 13px;
  color: #626d75;
  line-height: 25px;
}
.mission .mission_txt p {
  margin: 0 0 30px;
}
.mission .block_lien {
  border: 1px solid #f6f5f5;
  border-radius: 5px;
  padding: 30px 40px;
  margin-bottom: 30px;
}
.mission .block_lien h3, .mission .block_lien blockquote {
  margin: 0;
  font-size: 15px;
}
.mission .block_lien p {
  margin: 20px 0 30px;
  color: #626d75;
  font-size: 13px;
}

.airplane {
  position: absolute;
  height: 30vw;
  width: 100%;
  top: -13vw;
}

.histoire .histoire_txt p {
  opacity: 0.7;
  margin: 80px 0 0;
  font-size: 13px;
  line-height: 25px;
}

.vert {
  position: relative;
}
.vert .block_contenu {
  padding: 40px 30px;
  border-radius: 5px;
  position: relative;
  top: -90px;
  margin-bottom: 70px;
}
.vert .block_contenu h2 {
  margin: 0;
}
.vert .block_contenu p {
  opacity: 0.7;
  margin: 30px 0 50px;
  font-size: 13px;
  line-height: 25px;
}
.vert .block_contenu .avantage {
  position: relative;
}
.vert .block_contenu .avantage p {
  font-size: 13px;
  color: #286953 !important;
  line-height: 17px;
  padding-left: 35px;
  margin: 16px 0 0;
}
.vert .block_contenu .avantage span {
  position: absolute;
}

.equipe {
  background-size: cover !important;
  background-position: top center !important;
}
.equipe .course_txt {
  margin: 80px 0;
  padding: 30px;
  border-radius: 5px;
}
.equipe .course_txt h2 {
  margin: 0;
}
.equipe .course_txt p {
  margin: 40px 0;
  font-size: 13px;
  line-height: 25px;
  opacity: 0.7;
}

@media (max-width: 1200px) {
  .airplane {
    height: 33vw;
    top: -20vw;
  }
}
@media (max-width: 992px) {
  .airplane {
    height: 43vw;
    top: -33vw;
  }
}
@media (max-width: 768px) {
  .mission h2 {
    padding-bottom: 40px;
  }
  .mission .block_lien {
    padding: 30px;
    margin-bottom: 30px;
  }
  .mission .block_lien h3, .mission .block_lien blockquote {
    margin: 0;
  }
  .mission .block_lien p {
    margin: 20px 0 30px;
    color: #626d75;
  }
  .airplane {
    height: 43vw;
    top: -30vw;
  }
  .histoire .histoire_txt p {
    margin: 40px 0 0;
  }
}
@media (max-width: 576px) {
  .airplane {
    height: 200px;
    top: -180px;
  }
  .vert .block_contenu {
    padding: 20px 15px;
    margin-bottom: 50px;
  }
  .vert .block_contenu p {
    opacity: 0.7;
    margin: 20px 0;
    line-height: 18px;
  }
  .equipe .course_txt {
    padding: 15px;
  }
  .equipe .course_txt p {
    margin: 20px 0;
  }
}
.blog_bg {
  position: relative;
  padding-top: 33%;
  background-size: cover;
  background-position: top center;
  margin-top: 95px;
}
.blog_bg .entete {
  padding: 60px;
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
}
.blog_bg .entete h2 {
  margin: 0 0 60px;
}
.blog_bg .entete p {
  opacity: 0.7;
  font-size: 13px;
  line-height: 25px;
}
.blog_bg .entete .communique {
  padding: 30px 0 50px;
}
.blog_bg .paddind_blanc {
  height: 200px;
  position: absolute;
  width: 100%;
  bottom: 0;
}
.blog_bg .diagonal_gris {
  z-index: 2;
}

.blog_post {
  position: relative;
}
.blog_post .post_views {
  margin: auto;
  margin-bottom: 70px;
  padding-bottom: 30px;
  max-width: 300px;
}
.blog_post .post_views h3, .blog_post .post_views blockquote {
  font-size: 15px;
  height: 80px;
  line-height: 20px;
}
.blog_post .post_views p {
  font-size: 13px;
  font-style: italic;
  opacity: 0.3;
}
.blog_post .post_views:hover {
  text-decoration: none;
}
.blog_post .post_views:hover h3, .blog_post .post_views:hover blockquote, .blog_post .post_views:hover p {
  opacity: 1;
  text-decoration: none;
}
.blog_post .post_views:hover img {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .blog_bg {
    padding-top: 40%;
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 40px;
  }
  .blog_bg .entete {
    margin-bottom: 20px;
  }
  .blog_bg .entete h2 {
    margin: 0 0 40px;
  }
  .blog_post .post_views {
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
  .blog_post .post_views h3, .blog_post .post_views blockquote {
    height: 60px;
  }
}
@media (max-width: 576px) {
  .blog_bg .entete {
    padding: 20px;
    margin-bottom: 20px;
  }
  .blog_bg .entete h2 {
    margin: 0 0 40px;
  }
  .blog_bg .entete p {
    line-height: 25px;
  }
  .blog_bg .entete .communique {
    padding: 20px 0;
  }
  .blog_bg .paddind_blanc {
    height: 200px;
  }
  .blog_post .form-inline .input-group {
    margin: 30px auto;
  }
  .blog_post .post_views {
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
  .blog_post .post_views h3, .blog_post .post_views blockquote {
    font-size: 15px;
    height: 60px;
    line-height: 20px;
  }
}
.contact_bg {
  position: relative;
  padding-top: 25%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  margin-top: 95px;
}
.contact_bg .image {
  height: 250px;
  background-position: top center;
  background-size: cover;
  display: none;
}
.contact_bg .entete {
  padding: 30px 30px 60px;
  position: relative;
  z-index: 2;
  margin-bottom: 50px;
}
.contact_bg .entete h2 {
  margin: 30px 0 45px;
}
.contact_bg .entete .contact_txt {
  font-size: 16px;
  position: relative;
  margin-bottom: 20px;
}
.contact_bg .entete .contact_txt span {
  position: absolute;
}
.contact_bg .entete .contact_txt p {
  padding-left: 20px;
  margin: 0;
}
.contact_bg .entete .contact_txt a {
  padding-left: 25px;
}
.contact_bg .entete .block_tel {
  border-radius: 5px;
  padding: 45px 30px 35px;
  margin: 30px 0;
  position: relative;
}
.contact_bg .entete .block_tel .block_i {
  position: absolute;
  top: -15px;
  color: black;
  background: white;
  padding: 10px;
  font-size: 20px;
  width: 40px;
  text-align: center;
  border-radius: 20px;
  left: -15px;
}
.contact_bg .entete .block_tel .phones {
  font-size: 16px;
  line-height: 30px;
}
.contact_bg .entete .block_tel .phones div {
  float: right;
}
.contact_bg .entete .block_form {
  padding: 30px 20px;
  border: 1px solid #f6f5f5;
  border-radius: 5px;
}
.contact_bg .entete .block_form h3, .contact_bg .entete .block_form blockquote {
  margin: 0 0 40px;
  font-size: 15px;
}
.contact_bg .paddind_blanc {
  height: 200px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

@media (max-width: 768px) {
  .contact_bg {
    padding-top: 0;
    margin-top: 40px;
  }
  .contact_bg .image {
    display: block;
  }
}
@media (max-width: 576px) {
  .contact_bg .entete {
    padding: 30px 15px;
  }
  .contact_bg .entete h2 {
    margin: 0 0 20px;
  }
  .contact_bg .entete .block_tel {
    border-radius: 5px;
    padding: 20px 20px 5px;
  }
  .contact_bg .entete .block_tel .phones {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }
  .contact_bg .entete .block_tel .phones div {
    float: none;
    margin-bottom: 15px;
  }
}
.video {
  position: relative;
  margin-top: 95px;
}
.video .videohome {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.video .videohome video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  transform: translateX(-50%) translateY(-50%);
}
.video .videohome .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
.video .home_header_left {
  padding-top: 13vw;
  float: left;
  width: 50%;
  padding-bottom: 23vw;
}
.video .home_header_left .titre_banner {
  font-size: 33px;
  margin: 0 0 40px;
}
.video .home_header_right {
  float: right;
  padding-top: 18vw;
  padding-right: 100px;
  margin-top: 270px;
  margin-bottom: 80px;
  position: relative;
  display: none;
}
.video .home_header_right .iphone {
  position: absolute;
  right: -30px;
  bottom: -160px;
}
.video .home_header_right .block_blue {
  padding: 30px;
  border-radius: 5px;
  max-width: 390px;
  position: relative;
  z-index: 2;
  top: 40px;
  display: inline-block;
}
.video .home_header_right .block_blue .titre {
  font-size: 14px;
  margin: 0 0 35px;
}
.video .home_header_right .block_blue .texte {
  font-size: 13px;
  margin: 0 0 40px;
}
.video .home_header_right .block_blue a {
  opacity: 1;
}
.video .home_header_right .block_blue a:hover {
  opacity: 0.7;
}
.video .play_btn {
  position: absolute;
  top: 40%;
}
.video .play_btn a {
  opacity: 0.5;
}
.video .play_btn a:hover {
  opacity: 1;
}
.video .mobile {
  display: none;
}

.transport {
  padding: 35px 0 60px;
  position: relative;
}
.transport h2 {
  padding-bottom: 60px;
}
.transport a {
  padding: 25px 15px 50px;
  border-radius: 5px;
  margin-bottom: 30px;
}
.transport a div {
  margin: auto;
}
.transport a h3, .transport a blockquote {
  font-size: 15px;
}
.transport a p {
  font-size: 13px;
  margin-top: 30px;
  opacity: 0.4;
}
.transport a:hover {
  text-decoration: none;
}
.transport a:hover h3, .transport a:hover blockquote, .transport a:hover p {
  opacity: 1;
}

.service {
  position: relative;
  padding-bottom: 60px;
}
.service .service_contenu {
  border-radius: 5px;
  padding: 60px 50px;
}
.service .service_contenu h2 {
  margin: 0;
}
.service .service_contenu p {
  font-size: 13px;
  opacity: 0.7;
  margin: 0 0 40px;
  line-height: 25px;
}

.course {
  position: relative;
}
.course .row {
  padding: 160px 0 80px;
}
.course .row .block_contenu {
  padding: 15px 25px 40px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #d1d5d8;
}
.course .row .block_contenu p {
  opacity: 0.7;
  font-size: 13px;
  line-height: 25px;
}

.blog {
  position: relative;
  padding-top: 60px;
  padding-bottom: 100px;
}
.blog h2 {
  font-size: 20px;
}
.blog .block_blog {
  border-radius: 5px;
  border: 1px solid #d1d5d8;
  padding: 15px 30px 50px;
  margin-top: 40px;
}
.blog .block_blog h3, .blog .block_blog blockquote {
  font-size: 15px;
  height: 50px;
}
.blog .block_blog p {
  font-size: 13px;
  opacity: 0.3;
}
.blog .block_blog:hover {
  border: 1px solid #2f75e7;
  text-decoration: none;
}
.blog .block_blog:hover h3, .blog .block_blog:hover blockquote {
  text-decoration: underline;
}
.blog .block_blog:hover p {
  opacity: 1;
}

@media (max-width: 1200px) {
  .video .home_header_left .titre_banner {
    font-size: 28px;
  }
  .video .home_header_right {
    padding-right: 75px;
  }
}
@media (max-width: 992px) {
  .video .home_header_left {
    width: auto;
    padding-bottom: 35vw;
  }
  .video .home_header_right {
    padding-top: 0;
    margin-top: 60px;
  }
  .course .row .block_contenu {
    margin-top: 180px;
  }
}
@media (max-width: 768px) {
  .video {
    margin-top: 40px;
  }
  .video .videohome {
    display: none;
  }
  .video .home_header_left {
    padding-top: 90px;
    padding-bottom: 40vw;
  }
  .video .home_header_left .titre_banner {
    font-size: 28px;
  }
  .video .ordi {
    display: none;
  }
  .video .mobile {
    display: block;
  }
  .transport {
    padding: 35px 0 35px;
  }
  .transport h2 {
    padding-bottom: 40px;
  }
  .transport a {
    width: 280px;
    margin: 0 auto 30px;
  }
  .course .row {
    padding: 160px 0 40px;
  }
  .course .row .block_contenu {
    padding: 15px 15px 20px;
    margin-top: 100px;
  }
  .blog {
    padding-bottom: 150px;
  }
  .blog h2 {
    margin-bottom: 25px;
  }
  .blog .block_blog {
    padding: 15px 30px 25px;
    margin: 40px auto 0;
    width: 350px;
  }
}
@media (max-width: 576px) {
  .video .home_header_left {
    padding-top: 60px;
  }
  .video .home_header_left .titre_banner {
    font-size: 22px;
    margin: 0 0 30px;
  }
  .video .home_header_right {
    padding-right: 0;
    margin-bottom: 40px;
  }
  .video .home_header_right .iphone {
    display: none;
  }
  .video .home_header_right .block_blue {
    padding: 20px;
    max-width: 390px;
  }
  .video .home_header_right .block_blue .titre {
    font-size: 14px;
    margin: 0 0 35px;
  }
  .video .home_header_right .block_blue .texte {
    font-size: 13px;
    margin: 0 0 40px;
  }
  .transport a {
    padding: 20px 15px 20px;
    margin-bottom: 20px;
    width: 250px;
  }
  .transport a p {
    display: none;
  }
  .service {
    padding-bottom: 60px;
  }
  .service .service_contenu {
    padding: 20px 10px;
  }
  .service .service_contenu p {
    margin: 0 0 15px;
  }
  .course .row {
    padding: 100px 0 40px;
  }
  .course .row .block_contenu {
    padding: 15px 15px 15px;
    margin-top: 80px;
  }
  .course .row .block_contenu p {
    line-height: 18px;
  }
  .blog {
    padding-top: 0;
    padding-bottom: 40px;
  }
  .blog .block_blog {
    padding: 15px;
    margin: 20px auto 0;
    width: 250px;
  }
}
@media (max-width: 420px) {
  .video .home_header_right .block_blue a img {
    max-width: 100px;
  }
}
.banner.team {
  height: 60vh;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
.banner.team .img-fluid {
  display: none;
}

.experts {
  padding: 100px 0 0;
}
.experts .fiche {
  margin-bottom: 30px;
}
.experts .fiche .img-fluid {
  border-radius: 5px;
  width: 100%;
}
.experts .fiche .description {
  float: left;
  width: calc(100% - 45px);
}
.experts .fiche .description h3, .experts .fiche .description blockquote {
  font-size: 15px;
  margin-top: 30px;
}
.experts .fiche .description p {
  font-size: 13px;
  font-style: italic;
  opacity: 0.7;
}
.experts .fiche .courriel {
  float: right;
}
.experts .fiche .courriel a {
  border-radius: 20px;
  height: 40px;
  width: 40px;
  background: #1e4990;
  display: inline-block;
  margin-top: 30px;
}
.experts .fiche .courriel a img {
  margin-top: 12px;
  margin-left: 10px;
}
.experts .fiche .courriel a:hover {
  background: #1f2e39;
}

.expert_img img {
  width: 100%;
}

@media (max-width: 992px) {
  .banner.team {
    height: auto;
  }
  .banner.team .img-fluid {
    display: block;
  }
}
@media (max-width: 576px) {
  .experts {
    padding: 50px 0 0;
  }
  .experts .fiche {
    width: 250px;
    margin: 0 auto 30px;
  }
}
.recherche {
  padding-bottom: 150px;
}
.resultat {
  padding: 0 0 100px;
}
.resultat .block_blog {
  border-radius: 5px;
  border: 1px solid #d1d5d8;
  padding: 15px 30px 50px;
  margin-top: 40px;
}
.resultat .block_blog h3, .resultat .block_blog blockquote {
  font-size: 15px;
  height: 50px;
}
.resultat .block_blog p {
  font-size: 13px;
}
.resultat .block_blog:hover {
  border: 1px solid #2f75e7;
  text-decoration: none;
}
.resultat .block_blog:hover h3, .resultat .block_blog:hover blockquote {
  text-decoration: underline;
}
.resultat .pagination_resultat {
  margin-top: 60px;
}

@media (max-width: 768px) {
  .resultat {
    margin-bottom: 150px;
  }
  .resultat h2 {
    margin-bottom: 25px;
  }
  .resultat .block_blog {
    padding: 15px 30px 25px;
    margin: 40px auto 0;
    width: 350px;
  }
}
@media (max-width: 576px) {
  .resultat {
    margin-top: 0;
    margin-bottom: 40px;
  }
  .resultat .block_blog {
    padding: 15px;
    margin: 20px auto 0;
    width: 280px;
  }
}
.nos_services {
  position: relative;
  padding-bottom: 200px;
}
.nos_services h2 {
  margin-bottom: 60px;
}
.nos_services .menu_service a {
  position: relative;
  margin-right: 40px;
  opacity: 0.3;
}
.nos_services .menu_service a .number_service {
  opacity: 0.05;
  font-size: 95px;
  position: absolute;
  top: 33px;
}
.nos_services .menu_service a h3, .nos_services .menu_service a blockquote {
  font-size: 14px;
  line-height: 95px;
  padding-left: 70px;
  margin: 0;
  border-bottom: 1px solid #f4f4f5;
}
.nos_services .menu_service a h3 i, .nos_services .menu_service a blockquote i {
  font-size: 20px;
  position: absolute;
  right: 0;
  line-height: 95px;
}
.nos_services .menu_service a:hover {
  text-decoration: none;
  opacity: 1;
}
.nos_services .menu_service a.active {
  opacity: 1;
}
.nos_services .service_txt {
  margin-top: 15px;
  font-size: 13px;
  line-height: 25px;
  opacity: 0.7;
}
.nos_services .service_txt ul {
  padding-left: 30px;
  list-style-type: disc;
}

.service_contact {
  position: relative;
  padding: 100px 0;
}

@media (max-width: 992px) {
  .nos_services .menu_service a {
    margin-right: 0;
  }
  .nos_services .menu_service a .number_service {
    font-size: 30px;
    top: 6px;
  }
  .nos_services .menu_service a h3, .nos_services .menu_service a blockquote {
    font-size: 12px;
    line-height: 30px;
    padding-left: 25px;
  }
  .nos_services .menu_service a h3 i, .nos_services .menu_service a blockquote i {
    display: none;
  }
}
@media (max-width: 768px) {
  .nos_services {
    padding-bottom: 150px;
  }
  .nos_services h2 {
    margin-bottom: 60px;
  }
  .nos_services .service_txt {
    line-height: 18px;
  }
  .nos_services .service_txt ul {
    padding-left: 20px;
  }
}
@media (max-width: 576px) {
  .nos_services {
    padding-bottom: 100px;
  }
  .nos_services h2 {
    margin-bottom: 30px;
  }
  .service_contact {
    position: relative;
    padding: 30px 0;
  }
}
.caracteristique {
  position: relative;
}
.caracteristique .bg_image {
  position: relative;
  z-index: 2;
  background-position: top left !important;
  background-size: contain !important;
}
.caracteristique .bg_image .block_contenu {
  padding: 25px 30px 50px;
  border: 1px solid #f6f5f5;
  border-radius: 5px;
  margin: 5vw 0;
}
.caracteristique .bg_image .block_contenu h2 {
  text-align: left;
}
.caracteristique .bg_image .block_contenu h4 {
  font-size: 13px;
  opacity: 0.7;
  margin-bottom: 60px;
}
.caracteristique .bg_image .block_contenu div {
  position: relative;
}
.caracteristique .bg_image .block_contenu div p {
  font-size: 13px;
  line-height: 17px;
  padding-left: 70px;
}
.caracteristique .bg_image .block_contenu div span {
  margin: 0 30px 0 20px;
  position: absolute;
}
.caracteristique .diagonal_bleu {
  bottom: 150px;
}
.caracteristique .padding_bleu {
  height: 150px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

.contenu {
  padding: 80px 0 0;
}
.contenu .description {
  font-size: 13px;
  line-height: 35px;
}
.contenu .description ul {
  padding-left: 30px;
  list-style-type: disc;
}
.question {
  position: relative;
}
.question img {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.question .padding_img {
  padding: 30vw 15px 14vw;
}

@media (max-width: 1200px) {
  .caracteristique .bg_image {
    background-position: center center !important;
    background-size: cover !important;
  }
  .question .padding_img {
    padding: 30vw 15px 10vw;
  }
}
@media (max-width: 768px) {
  .contenu {
    padding: 50px 0;
  }
}
@media (max-width: 576px) {
  .caracteristique .bg_image .block_contenu {
    padding: 0px 15px 25px;
    background: rgba(255, 255, 255, 0.8) !important;
  }
  .caracteristique .bg_image .block_contenu div p {
    padding-left: 30px;
  }
  .caracteristique .bg_image .block_contenu div span {
    margin: 0 5px;
  }
  .question .padding_img {
    padding: 34vw 15px 0;
  }
}
.landing__imagetext {
  background-color: #2f75e7;
  position: relative;
  padding-bottom: 125px;
}
.landing__imagetext--dark {
  background-color: #1f2e39;
  padding-bottom: 0;
  padding-top: 25px;
}
.landing__imagetext--dark img {
  order: 2;
}
@media (min-width: 768px) {
  .landing__imagetext--dark img {
    order: initial;
    align-self: end;
  }
}
.landing__imagetext__block {
  display: grid;
}
@media (min-width: 768px) {
  .landing__imagetext__block {
    grid-template-columns: repeat(12, 1fr);
    column-gap: 3.75rem;
  }
}
.landing__imagetext img {
  max-width: 100%;
}
@media (min-width: 768px) {
  .landing__imagetext img {
    grid-column: 1/span 5;
  }
}
.landing__imagetext__inner {
  padding-top: 1.25rem;
}
@media (min-width: 768px) {
  .landing__imagetext__inner {
    grid-column: 6/span 7;
    max-width: 380px;
    margin-left: 0;
    padding: 0;
  }
}
@media (min-width: 992px) {
  .landing__imagetext__inner {
    max-width: 515px;
  }
}
@media (min-width: 1200px) {
  .landing__imagetext__inner {
    max-width: 625px;
  }
}
@media (min-width: 1600px) {
  .landing__imagetext__inner {
    margin-left: 60px;
  }
}
.landing__imagetext__inner--last {
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
}
@media (min-width: 768px) {
  .landing__imagetext__inner--last {
    padding-top: 5rem;
  }
}
@media (min-width: 992px) {
  .landing__imagetext__inner--last {
    padding-top: 7.5rem;
    padding-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .landing__imagetext__inner--last {
    padding-top: 12.5rem;
  }
}
.landing__imagetext__inner h1 {
  color: white;
  font-size: 1.875rem;
}
@media (min-width: 992px) {
  .landing__imagetext__inner h1 {
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .landing__imagetext__inner h1 {
    font-size: 3.125rem;
  }
}
.landing__section2 {
  position: relative;
}
@media (min-width: 768px) {
  .landing__section2 {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 1.875rem;
  }
}
@media (min-width: 992px) {
  .landing__section2 {
    column-gap: 3.75rem;
  }
}
.landing__section2__block {
  grid-column: 1/span 5;
}
.landing__section2__block .frameVideo {
  margin: 1.875rem 0;
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}
.landing__section2__block .frameVideo iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.landing__section2__form {
  background-color: #1f2e39;
  padding: 1.25rem;
  margin-top: 1.875rem;
}
@media (min-width: 768px) {
  .landing__section2__form {
    grid-column: 6/span 7;
    margin-top: -20%;
  }
}
@media (min-width: 992px) {
  .landing__section2__form {
    padding: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .landing__section2__form {
    margin-top: -30%;
    padding: 5rem;
  }
}
.landing__section2__form form {
  margin-top: 3rem;
}
.landing__section2__form label {
  color: white;
  font-weight: 500;
  padding-bottom: 0.5rem;
  width: 100%;
}
.landing__section2__form label span {
  padding-top: 0.5rem;
  display: block;
}
.landing__section2__form label input, .landing__section2__form label select, .landing__section2__form label textarea {
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 3px;
  border: 1px solid #3A576D;
}
.landing__section2__form input[type=submit] {
  display: inline-block;
  font-size: 16px;
  padding: 10px 45px 10px 20px;
  border-radius: 20px;
  min-height: 40px;
  margin-bottom: 1rem;
  border: 2px solid #2f75e7;
  background-color: #2f75e7;
  color: white;
  background-image: url(../images/landing/paper-plane-solid.svg);
  background-repeat: no-repeat;
  background-position: 90% center;
  background-size: 15%;
}
.landing__section2__form input[type=submit]:hover {
  border: 2px solid white;
  text-decoration: none;
}
.landing__transport {
  margin-top: 1.875rem;
  margin-bottom: 3.75rem;
}
.landing__transport__repeater {
  display: grid;
  row-gap: 1.875rem;
}
@media (min-width: 768px) {
  .landing__transport__repeater {
    grid-template-columns: 1fr 1fr;
    column-gap: 1.875rem;
  }
}
.landing__transport__repeater article {
  background-color: #2f75e7;
  position: relative;
  min-height: 400px;
}
@media (min-width: 768px) {
  .landing__transport__repeater article {
    min-height: 470px;
  }
}
.landing__transport__repeater article .boxpadding {
  padding: 1.25rem 1.25rem 0 1.25rem;
}
@media (min-width: 768px) {
  .landing__transport__repeater article .boxpadding {
    padding: 2.5rem 2.5rem 0 2.5rem;
  }
}
@media (min-width: 992px) {
  .landing__transport__repeater article .boxpadding {
    padding: 3.75rem 3.75rem 0 3.75rem;
  }
}
.landing__transport__repeater article img {
  max-width: 100%;
  position: absolute;
  bottom: 0;
}
.landing__transport__repeater article h3, .landing__transport__repeater article blockquote {
  color: white;
  font-size: 1.75rem;
}
.landing__transport__repeater article a {
  position: relative;
  z-index: 1000;
}
.landing__btn {
  display: inline-block;
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 20px;
  line-height: 20px;
  min-height: 40px;
  border: 1px solid #1f2e39;
  background-color: #1f2e39;
  color: white;
}
.landing__btn:hover {
  border: 1px solid white;
  color: #1f2e39;
  text-decoration: none;
}
.landing__btn--primary {
  border: 1px solid #2f75e7;
  background-color: #2f75e7;
}
.landing__btn--primary:hover {
  border: 1px solid #2f75e7;
  text-decoration: none;
}
.landing__btn--form {
  border: 1px solid #498EFF;
  color: #498EFF;
  background-color: transparent;
}
.landing__btn--form:hover {
  border: 1px solid #498EFF;
  border: 1px solid white;
  background-color: #498EFF;
  text-decoration: none;
}
.landing__btn i {
  padding-right: 0.5rem;
}
.landing__header__h2 {
  text-align: left;
  font-size: 1.625rem;
  text-transform: none;
}
.landing__header__h2--white {
  color: white;
}
@media (min-width: 768px) {
  .landing__header {
    font-size: 2.25rem;
  }
}
.landing__p {
  margin: 1.875rem 0;
  line-height: 1.375rem;
}
.landing__p--white {
  color: white;
}
.landing__hidden--sm {
  display: none;
}
@media (min-width: 768px) {
  .landing__hidden--sm {
    display: block;
    position: absolute;
    bottom: 0;
    border-top: 3px solid #3A576D;
    width: 40%;
    padding-top: 3.75rem;
  }
}
.landing__hidden--lg {
  display: block;
}
@media (min-width: 768px) {
  .landing__hidden--lg {
    display: none;
  }
}
.landing__merci {
  background-color: #2f75e7;
  color: white;
  min-height: 400px;
}
@media (min-width: 768px) {
  .landing__merci {
    min-height: 550px;
  }
}
.landing__merci h1 {
  color: white;
  font-size: 1.875rem;
  margin-top: 0;
}
@media (min-width: 992px) {
  .landing__merci h1 {
    padding-top: 100px;
    font-size: 2.5rem;
  }
}
@media (min-width: 1200px) {
  .landing__merci h1 {
    font-size: 3.125rem;
  }
}
.landing__merci__text {
  margin: 0 auto;
}
@media (min-width: 992px) {
  .landing__merci__text {
    max-width: 660px;
  }
}