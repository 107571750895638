/**
 * Theme Name:  STCH
 * Theme URI:   http://www.transportstch.com/
 * Description: Thème développé pour STCH
 * Author:      Webit Interactive
 * Author URI:  http://webitinteractive.ca
 * Version:     0.1.0
 * Tags:
 * Text Domain: stch
 *
 * License:     GPLv2+
 * License URI: http://www.gnu.org/licenses/gpl-2.0.html
 */

@import
    "core/normalize",
    "core/bourbon/bourbon",
    "core/neat/neat",
    "core/variables",
    "core/functions",
    "core/mixins",
    "core/utilities";

@import
    "base/document",
    "base/typography",
    "base/icons",
    "base/buttons",
    "base/form",
    "base/nav",
    "base/pagination",
    "base/timeline",
    "base/twenty";

@import
    "components/menu";

@import
    "blocks/diagonal",
    "blocks/footer",
    "blocks/header",
    "blocks/menu-icon",
    "blocks/prettyPhoto",
    "blocks/question",
    "blocks/slick",
    "blocks/header-landing";

@import
    "views/404",
    "views/a-propos",
    "views/blog-all",
    "views/contact",
    "views/home",
    "views/nos-experts",
    "views/search",
    "views/services",
    "views/transport",
    "views/landing";
